const Image = ({ src, alt, width, height, isCircle, ...props }) => (
  <img
    src={src.startsWith("https://") ? src : `/images/${src}`}
    alt={alt || src}
    width={width}
    height={height}
    style={{ borderRadius: isCircle ? "35px" : 0 }}
    {...props}
  />
);

export default Image;
